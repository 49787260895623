/*
Application Settings Go Here
------------------------------------
This file acts as a bundler for all variables/mixins/themes, so they
can easily be swapped out without `core.scss` ever having to know.

For example:

@import './variables/colors';
@import './variables/components';
@import './themes/default';
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
html {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

ul {
  padding: 0 !important;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit !important;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.underline {
  text-decoration: underline;
}

.text-center {
  text-align: center !important;
}
.text-center input.pt-editable-input {
  text-align: center;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.overflow-visible {
  overflow: visible!important;
}

.v-middle {
  vertical-align: middle !important;
}

.over-auto {
  overflow: auto !important;
}

.over-hidden {
  overflow: hidden;
}

.over-x-hidden {
  overflow-x: hidden !important;
}

.over-x-scroll {
  overflow-x: scroll;
}

.over-x-auto {
  overflow-x: auto !important;
}

.over-y-hidden {
  overflow-y: hidden !important;
}

.over-y-scroll {
  overflow-y: scroll;
}

.over-y-auto {
  overflow-y: auto !important;
}

.float-r {
  float: right !important;
}

.cursor-none {
  cursor: none;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-nodrop {
  cursor: no-drop;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-900 {
  font-weight: 900 !important;
}

.minw-fc {
  min-width: fit-content !important;
}

.width-fc {
  width: fit-content;
}

.full-width {
  width: 100% !important;
}

.full-height {
  height: 100%;
}

.min-full-height {
  min-height: 100%;
}

.no-margin {
  margin: 0 !important;
}

.mt-0 {
  margin-top: 0; }

.mt-half {
  margin-top: 0.5rem;
}

.mt-1 {
  margin-top: 1rem !important;
}

.mt-2 {
  margin-top: 2rem;
}

.mr-half {
  margin-right: 0.5rem;
}

.mr-1 {
  margin-right: 1rem !important;
}

.mr-2 {
  margin-right: 2rem;
}

.mb-half {
  margin-bottom: 0.5rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.pre {
  white-space: pre;
}

.pre-w {
  white-space: pre-wrap;
}

.pre-w-i {
  white-space: pre-wrap !important;
}

.nowrap {
  white-space: nowrap;
}

.wb-ba {
  word-break: break-all;
}

.of-con {
  object-fit: contain;
}

.ml-half {
  margin-left: 0.5rem !important;
}

.ml-1 {
  margin-left: 1rem !important;
}

.ml-2 {
  margin-left: 2rem;
}

.m-auto {
  margin: auto;
}

.no-padding {
  padding: 0 !important;
}

.p-half {
  padding: 0.5rem;
}

.p-1 {
  padding: 1rem;
}

.pt-half {
  padding-top: 0.5rem !important;
}

.pt-1 {
  padding-top: 1rem;
}

.pr-half {
  padding-right: 0.5rem;
}

.pr-1 {
  padding-right: 1rem;
}

.pb-half {
  padding-bottom: 0.5rem;
}

.pb-1 {
  padding-bottom: 1rem;
}

.pl-half {
  padding-left: 0.5rem;
}

.pl-1 {
  padding-left: 1rem;
}

.border-gainsboro {
  border: 1px solid gainsboro;
}

.d-none {
  display: table-cell !important;
}

.d-td {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-grid {
  display: grid !important;
}

.d-block {
  display: block !important;
}

.d-inblock {
  display: inline-block !important;
}

.pos-rel {
  position: relative;
}

.pos-abs {
  position: absolute;
}

.pos-sticky {
  position: sticky;
}

.fd-r {
  flex-direction: row !important;
}

.fd-rr {
  flex-direction: row-reverse;
}

.fd-c {
  flex-direction: column;
}

.fd-cr {
  flex-direction: column-reverse;
}

.fw-w {
  flex-wrap: wrap;
}

.ai-c {
  align-items: center !important;
}

.ai-fs {
  align-items: flex-start !important;
}

.ai-fe {
  align-items: flex-end;
}

.ai-b {
  align-items: baseline;
}

.as-fs {
  align-self: flex-start;
}

.as-c {
  align-self: center;
}

.as-fe {
  align-self: flex-end;
}

.jc-c {
  justify-content: center !important;
}

.jc-fe {
  justify-content: flex-end !important;
}

.jc-sb {
  justify-content: space-between;
}

.jc-sa {
  justify-content: space-around;
}

.flex-1 {
  flex: 1;
}

.gray {
  color: darkgray;
}

.link{
  cursor: pointer;
  transition: 'color 0.24s';
}

.link.gray:hover {
  color: gray;
  transition: 'color 0.24s';
}

.react-html ul {
	padding-left: 40px!important;
}

table.react-table.striped tbody tr:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.03);
}

table.react-table.striped tbody tr:nth-child(even) {
  background-color: white;
}

table.react-table thead th {
  font-size: 1.1rem;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.87);
}

table.react-table tbody td {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.87);
}

.ReactTable .-pagination .-center .-pageInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.ReactTable .-pagination .-center .-pageInfo .-currentPage {
  display: inline-block;
  margin-left: 1rem;
  margin-right: 1rem;
}
.ReactTable .-pagination .-center .-pageInfo .-totalPages {
  display: inline-block;
  margin-left: 1rem;
}

.before-white::before {
  color: white !important;
}

input.pt-editable-input {
  width: 100% !important;
}

.pt-button[class*="pt-icon-"]:empty:before {
  margin-right: 0;
}

.full-width .pt-tab-indicator-wrapper,
.full-width .pt-tab-list {
  width: 100% !important;
}

table.pt-table.disabled {
  background-color: #e8e8e8;
}
table.pt-table.disabled td {
  color: darkgray;
  pointer-events: none;
}
